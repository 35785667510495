import { getEndTimeFromStartTimeAndDuration } from 'common/dist/time';
import availabilityCalendarHelpers from 'common/dist/virtuals/AvailabilityCalendar';
import { isAvailable as _isAvailable } from 'common/dist/virtuals/AvailabilityCalendar';
import { some, every, get, set } from 'lodash';

import { ApiService } from 'spc/shared/api/api.service';
import { getAddOns, getVenue } from 'spc/recos/reco-request/helpers';
import { MenuDisplayService } from '../menu-display.service';
import { integerToCivilianTime } from 'common/dist/time';
import { RecoRequestService } from './reco-request.service';
// constant
import { ANALYTICS_EVENTS } from '../../constants/ENUMS/analyticsEvents';

class RecoRequestController {
  EVENT_STAGE = 'Event';
  TYPE_STAGE = 'Type';
  DATE_STAGE = 'Date';
  MENU_STAGE = 'Menu';
  DRINK_STAGE = 'Drinks';
  ADDON_STAGE = 'AddOns';
  SUBMIT_STAGE = 'Submit';
  origin: 'recommendation' | 'venue-view-space' | 'venue-view-venue' | 'client-add-venue' | 'conversation' | 'conversation-continued';
  message: string;
  client: any;
  space: any;
  venue: any;
  lead: any;
  menus: any[];
  drinks: any[];
  leads: any[];
  addOns: any[];
  recommendation: any;
  request: any;
  conversation: any;
  displayBreakdownSidebar: boolean;
  displaySpacesSection: boolean;
  spaceIndex: number;
  close: ({ request, status, lead }: { request?: any, status?: string, lead?: string }) => any;
  temp: {
    leadName: string
  };
  ui: {
    stage?: 'Type' | 'Event' | 'Date' | 'Menu' | 'Drinks' | 'AddOns' | 'Submit';
    newEvent?: boolean
    authTab?: 'register' | 'login';
  } = { newEvent: false, authTab: 'login' };
  submitting: boolean = false;
  calendarMap: any;
  availabilityCalendars: any;

  constructor(
    private $api: ApiService,
    private $analytics,
    private ENUMS,
    private unwrapError,
    private menuDetailModal,
    private $cloudinary,
    private $user,
    private $q,
    private $scope,
    private createEditEventModal,
    private menuDisplayService: MenuDisplayService,
    private recoRequestService: RecoRequestService,
    private AvailabilityFactory,
    private $clickOk
  ) {
    'ngInject';
  }


  $onInit = () => {
    this.venue = getVenue({ venue: this.venue, recommendation: this.recommendation, request: this.request });
    if (this.origin === 'recommendation') {
      this.getActiveLeads();
      this.client = this.$user.isHostOrAdmin() ? this.lead.primaryClient : this.$user.$;
    /**
     * If this view is being opened from a recommendation, all the documents
     * we'll be editing are already persisted in the DB. Now, it' just a matter
     * of fetching them if needed.
     */
      this.getActiveProposal()
        .then(() => {
          this.menus = this.getMenus();
          this.drinks = this.getDrinks();
          this.determineStage();
        })
        .then(() => this.trackEvent(ANALYTICS_EVENTS.recoRequest.openedFromReco));

    } else if (this.origin === 'client-add-venue') {
      /**
       * If this view is being opened form the client-add-venue modal, we know the lead, the venue but not the space so we need to handle it as if the client wants to attach a request to this lead.
       *
       */
      if (this.venue.status === 'Lite') {
        this.changeUiStage(this.EVENT_STAGE);
        this.trackEvent(ANALYTICS_EVENTS.recoRequest.openedFromClientAddLiteVenue);
      } else {
        this.selectLead(this.lead)
          .then(() => {
            this.determineStage();
            this.trackEvent(ANALYTICS_EVENTS.recoRequest.openedFromClientAddVenue);
          });
      }
    } else if (['venue-view-space', 'venue-view-venue', 'conversation'].includes(this.origin)) {
    /**
     * If this view is being opened through a space or venue on venue view, we know what venue they're interested in. We may even know the space.
     * We don't yet know whether the user wants to create a new event or attach it to an existing lead.
     */
      this.getActiveLeads()
        .then(() => {
          if (this.origin === 'conversation') {
            this.trackEvent(ANALYTICS_EVENTS.recoRequest.openedFromInquiry);
          } else {
            this.venue.status === 'Lite' ? this.trackEvent(ANALYTICS_EVENTS.recoRequest.openedFromLiteVenueView) : this.trackEvent(ANALYTICS_EVENTS.recoRequest.openedFromVenueView);
          }
        });
    } else if (this.origin === 'conversation-continued') {
      this.ui.newEvent = !get(this.request, 'admin.associations.lead');
      this.menuDisplayService.addVenueToList(this.venue);
      this.addOns = this.venue.data.addOns;
      this.trackEvent(ANALYTICS_EVENTS.recoRequest.openedFromInquiry);
      const stage = this.determineRequestStage();
      this.changeUiStage(stage);
    }

    this.$scope.$on('CHANGE_TAB', (ev, params) => {
      ev.stopPropagation();
      this.ui.authTab = params.tab.toLowerCase();
    });

    this.$scope.$on('REGISTER_SUCCESS', (ev, params) => {
      const EVENT_NAME = 'registered';
      this.$analytics.$register(this.$user.$);
      this.$analytics.$trackEvent(EVENT_NAME);
    });

    this.$scope.$on('LOGIN_SUCCESS', (ev, param) => {
      const EVENT_NAME = 'loggedIn';
      this.$analytics.$trackEvent(EVENT_NAME);
      this.$analytics.$identify(this.$user.$);
    });

  }

  selectLiteSpace = (space) => {
    if (this.origin === 'client-add-venue') {
      this.space = space;
      this.createRecommendation({ createProposal: false })
        .then( () => {
          this.trackEvent(ANALYTICS_EVENTS.recoRequest.selectedLiteSpace);
          return this.close({ status: 'Lite' });
        });
    }
    else if (this.origin === 'venue-view-venue' && !this.leads.length) {
      this.shellEventWithLite(space);
    }
    else {
      this.space = space;
    }
  }

  shellEventWithLite = (space) => {
    const preFilledData = { venueAddress: this.venue.data.address };
      this.createEditEventModal({ editEvent: false, preFilledData: preFilledData })
        .then((res) => {
          if (get(res.value, 'data')) {
            this.space = space;
            this.lead = res.value.data.lead;
            this.createRecommendation({ createProposal: false })
              .then( () => {
                this.trackEvent(ANALYTICS_EVENTS.recoRequest.openedFromClientAddLiteVenue);
                return this.close({ status: 'Lite', lead: this.lead });
              });
          }
          return this.close({ status: 'Lite', lead: this.lead });
          });
  }

  setScrollTop = () => {
    const div = document.querySelector('.ngdialog-content');
    div.scrollTop = 0;
  }

  shouldCreateRecommendation = () => {
    const hasRecommendation = this.recommendation;
    const hasVenue = this.venue;
    const hasLead = this.lead;
    const hasSpace = this.space;
    if (hasRecommendation) {
      return;
    }
    return hasVenue && hasSpace && hasLead;
  }

  private shouldCreateLead = () => {
    const hasLead = this.lead;
    const hasEventType = this.request.data.eventType;
    const hasDate = this.request.data.date;
    const hasTime = this.request.data.time;
    const hasDuration = this.request.data.duration;
    const hasSpace = this.request.selectedSpace;
    if (hasLead) {
      return;
    }
    return hasEventType && hasDate && hasSpace && hasTime && hasDuration;
  }

  updateSpace = (lead) => {
    const getCalendars  = this.AvailabilityFactory.getAvailabilityCalendarsForVenue(this.venue._id);
    const getAvailabilities = this.AvailabilityFactory.getAvailabilityForDate([this.space], lead.request.date);

    return this.$q.all([getCalendars, getAvailabilities])
      .then(([calendars]) => {
        this.availabilityCalendars = calendars;
      })
      .catch((error) => {
        this.unwrapError(error);
      });
  }

  getActiveProposal = () => {
    return this.$api.Requests.conversation(this.conversation._id ? this.conversation._id : this.conversation)
      .then((response: { conversation: any, request: any }) => {
        this.conversation = response.conversation;
        this.request = response.request;
        this.addOns = getAddOns({ recommendation: this.recommendation, request: this.request });
        this.venue = getVenue({ recommendation: this.recommendation, venue: null, request: this.request });
        this.menuDisplayService.addVenueToList(this.venue);
        this.menuDisplayService.updateStartTime({ startTime: this.request.data.time });
      })
      .catch(error => this.unwrapError(error));
  }

  getActiveLeads = () => {
    if (this.$user.isLoggedIn()) {
      return this.$api.Leads.getUserLeads({ activeOnly: true })
      .then((response) => {
        this.leads = response.leads;
        if (!this.leads.length) {
          return this.createEvent();
        }
        if (this.origin === 'recommendation') {
          this.determineStage();
        }
        else {
          this.changeUiStage(this.EVENT_STAGE);
        }
      })
      .catch(error => this.unwrapError(error));
    } else {
      return this.$q.resolve(this.createEvent());
    }
  }

  toggleBreakdownSidebar = () => {
    this.displayBreakdownSidebar = !this.displayBreakdownSidebar;
  }

  toggleSpacesSection = (index) => {
    this.spaceIndex = index;
    this.displaySpacesSection = !this.displaySpacesSection;
  }

  disableSelectLead = (lead) => {
  if (!this.space) {
      return;
    }
    return lead.recommendations.find(recommendation => recommendation.space._id.toString() === this.space._id.toString());
  }

  createEvent = () => {
    /**
     * If a user chooses to create a new event, we need need additional information before we can create the lead, recommendation or proposal.
     * We need the event type, date, time and duration.
     */
    this.ui.newEvent = true;
    if (get(this.venue, 'status') === 'Lite') {
      this.changeUiStage(this.EVENT_STAGE);
    }
    else {
      this.changeUiStage(this.TYPE_STAGE);
    }
  }

  leadHasRequiredData = () => {
    const requiredFields = [
      get(this.lead, 'request.date'),
      get(this.lead, 'request.duration')
    ];
    const guestCountFields = [
      get(this.lead, 'request.numGuests.min'),
      get(this.lead, 'request.numGuests.max'),
    ];
    return every(requiredFields) && some(guestCountFields);
  }

  isAvailableInCalendar = (space, date, startTime, endTime) => {
    const calendar = this.availabilityCalendars.find(_calendar => _calendar.space.toString() === space._id.toString());
    if (!calendar) {
      return;
    }
    return availabilityCalendarHelpers.isAvailable({
      calendar,
      date: date,
      times: {
        startTime: startTime,
        endTime: endTime
      }
    });
  }

  selectLead = async (lead) => {
    this.lead = lead;
    if (this.venue.status === 'Lite' ) {
      return this.createRecommendation({ createProposal: false });
    }
    if (this.space) {
      this.updateSpace(this.lead)
      .then(() => {
        const date = this.lead.request.date;
        let isAvailable;
        if (date && this.space && this.venue) {
          const startTime = this.lead.request.time;
          const endTime = getEndTimeFromStartTimeAndDuration(startTime, this.lead.request.duration);
          isAvailable = this.isAvailableInCalendar(this.space, date, startTime, endTime);
        }
        if (date && this.space && !isAvailable) {
          const showCancel = false;
          return this.$clickOk('This space is already booked for the given date and time.', showCancel);
        } else if (this.shouldCreateRecommendation()) {
          return this.createRecommendation({ createProposal: true });
        }
        else if (this.shouldCreateRecommendation() && this.lead.eventType === 'shellEvent') {
          return this.createRecommendation({ createProposal: true });
        }
      });
    }
    return this.selectType(this.lead.request.occasion, this.lead.request.customOccasion);
  }


  createRecommendation = ({ createProposal, bookingRequest }: { createProposal: boolean; bookingRequest?: string; }) => {
    const recommendation = {
      lead: this.lead._id,
      space: this.space._id,
      venue: this.venue._id,
      isInterested: this.venue.status === 'Lite' ? 'Liked Lite' : `Send Me A Proposal`,
      interestIndicationDate: Date.now(),
      isRecommended: true,
      createdThrough: this.origin === 'client-add-venue' ? 'Recos Venue Search' : 'Event Request',
      isVisible: true,
      isDeleted: false
    };
    return this.$api.Recos.createReco({ recommendation, createProposal, bookingRequest })
      .then((response) => {
        this.recommendation = response.recommendation;
        if (createProposal) {
          this.conversation = response.recommendation.conversation;
          return this.getActiveProposal();
        }
        if (this.venue.status === 'Lite') {
          return this.submitRequest();
        }
        return;
      })
      .then(() => {
        this.determineStage();
      })
      .catch(error => this.unwrapError(error));
  }

  determineStage = () => {
    let stage;
    if (!this.leadHasRequiredData() && ['venue-view-space', 'recommendation', 'client-add-venue'].includes(this.origin)) {
      stage = this.DATE_STAGE;
    }
    else if (this.ui.newEvent && !this.lead && !this.request) {
      stage = this.TYPE_STAGE;
    } else if (!this.lead && !get(this.request, 'admin.associations.lead')) {
      stage = 'Event';
    } else if (!this.conversation && !this.request) {
      stage = 'Date';
    } else {
      stage = this.determineRequestStage();
    }
    return this.changeUiStage(stage);
  }

  determineRequestStage = () => {
    if (!this.request.data.date || !this.space) {
      return 'Date';
    } else if (!this.request.data.menu) {
      return 'Menu';
    } else if (!this.request.data.drinks.length) {
      return 'Drinks';
    } else if (!this.request.data.addOns.length) {
      return 'AddOns';
    } else {
      return 'Submit';
    }
  }

  getClientsFromLead = () => {
    if (!this.lead) {
      return [];
    }

    const newERClient = this.lead.primaryClient._id ? this.lead.primaryClient._id.toString() : this.lead.primaryClient.toString();
    const clients = [{
      isPrimary: true,
      user: newERClient,
      permissions: {
        view: true,
        edit: true,
        sendAndReceiveMessages: true
      }
    }];
    return clients;
  }

  selectType = (eventType, customEventType?) => {

    const _doc = this.lead ? {
      clients: this.getClientsFromLead(),
      data: {
        eventType,
        customEventType,
        date: this.lead.request.date,
        time: this.lead.request.time,
        groupSize: get(this.lead, 'request.numGuests.max') || get(this.lead, 'request.numGuests.min')
      },
      admin: {
        associations: {
          lead: this.lead._id
        }
      }
    } : {
      data: {
        eventType,
        customEventType
      }
    };
    let persistRequest;
    if (this.request && this.request._id) {
      this.request.data.eventType = eventType;
      this.request.data.customEventType = customEventType;
      persistRequest = this.saveRequest();
    } else if (this.conversation) {
      set(_doc, 'admin.createdThrough', this.ENUMS.proposalCreatedThrough.ER_INQUIRY);
      persistRequest = this.$api.Conversations.createProposal({ conversation: this.conversation, request: _doc })
        .then(res => this.request = get(res, 'request'));
    } else {
      let proposalCreatedThrough;
      if (this.origin === 'venue-view-venue') {
        proposalCreatedThrough = this.ENUMS.proposalCreatedThrough.ER_VENUE_VIEW_VENUE;
      } else if (this.origin === 'venue-view-space') {
        proposalCreatedThrough = this.ENUMS.proposalCreatedThrough.ER_VENUE_VIEW_SPACE;
      } else if (this.origin === 'client-add-venue') {
        proposalCreatedThrough = this.ENUMS.proposalCreatedThrough.ER_CONCIERGE_VENUE_SEARCH;
      }

      set(_doc, 'admin.createdThrough', proposalCreatedThrough);
      persistRequest = this.$api.Requests.create(this.venue._id, _doc)
        .then(res => this.request = get(res, 'data.request'))
        .catch(error => this.unwrapError(error));
    }
    return persistRequest
      .then(() => this.trackEvent(ANALYTICS_EVENTS.recoRequest.selectedType))
      .then(() => this.changeUiStage(this.DATE_STAGE));
  }

  getMenus = () => {
    return this.menuDisplayService.FILTERED_VENUE_MENU_MAP[this.venue._id];
  }

  getDrinks = () => {
    return this.drinks || get(this.recommendation, 'venue.drinks') || get(this.venue, 'drinks');
  }

  selectSpace = () => {
    return this.saveRequest()
      .then(() => {
        this.space = this.request.selectedSpace;
        if (this.shouldCreateRecommendation()) {
          this.createRecommendation({ createProposal: !this.conversation, bookingRequest: this.request._id.toString() });
        }
        if (!this.request.venue.menus) {
          this.request.venue.menus = this.menus || this.venue.menus;
        }
        this.menuDisplayService.addVenueToList(this.request.venue);
        this.menuDisplayService.updateStartTime({ startTime: this.request.data.time });
        this.addOns = getAddOns({ recommendation: this.recommendation, request: this.request });
        this.trackEvent(ANALYTICS_EVENTS.recoRequest.selectedSpace);
        this.changeUiStage(this.MENU_STAGE);
      });
  }

  selectMenu = () => {
    return this.saveRequest()
      .then(() => this.trackEvent(ANALYTICS_EVENTS.recoRequest.selectedMenu))
      .then(() => {
        this.changeUiStage(this.DRINK_STAGE);
      });
  }

  selectDrinks = ({ goNext }) => {
    return this.saveRequest()
      .then(() => this.trackEvent(ANALYTICS_EVENTS.recoRequest.selectedDrink))
      .then(() => {
        goNext ? this.changeUiStage(this.ADDON_STAGE) : null;
      });
  }

  selectAddOns = ({ goNext }) => {
    return this.saveRequest()
      .then(() => this.trackEvent(ANALYTICS_EVENTS.recoRequest.selectedAddOns))
      .then(() => {
        if (goNext) {
          this.changeUiStage(this.SUBMIT_STAGE);
          if (!this.hasClient()) {
            this.ui.authTab = 'register';
          }
        }
      });
  }

  skipDrinkSelection = () => {
    return;
  }

  saveRequest = () => {
    return this.$api.Requests.save({ request: this.request })
      .then((response: { data: {request: any } }) => this.request = response.data.request)
      .catch(error => this.unwrapError(error));
  }

  submitRequest = () => {
    if (this.venue.status === 'Lite') {
      return this.close({ status: this.venue.status, lead: this.lead._id });
    }
    if (!this.canSubmitRequest()) {
      return;
    }
    this.submitting = true;
    const user = this.getClient();
    this.request.admin.assignee = user.owner;

    return this.$api.Requests.save({ request: this.request, submit: true, user, leadName: get(this.temp, 'leadName'), message: this.message })
      .then((response: any) => {
        this.request = response.data.request;
        this.trackEvent(ANALYTICS_EVENTS.recoRequest.submittedRequest);
        this.submitting = false;
        return this.close({ request: this.request });
      })
      .catch((error) => {
        this.submitting = false;
        this.unwrapError(error);
      });
  }

  // helpers

  hasClient = () => {
    return this.client || this.$user.isLoggedIn();
  }

  getClient = () => {
    return this.client || this.$user.$;
  }

  canSkipToSubmit = () => {
    if (!this.request) {
      return false;
    }
    const hasSpace = get(this.request, 'selectedSpace');
    const hasDate = get(this.request, 'data.date');
    const hasTime = get(this.request, 'data.time');
    const hasEventType = get(this.request, 'data.eventType');
    return hasSpace && hasDate && hasTime && hasEventType;
  }

  canSubmitRequest = () => {
    if (!this.request) {
      return false;
    }
    const hasSpace = get(this.request, 'selectedSpace');
    const hasDate = get(this.request, 'data.date');
    const hasTime = get(this.request, 'data.time');
    const hasClient = this.getClient();
    const hasEventType = get(this.request, 'data.eventType');
    return hasClient && hasSpace && hasDate && hasTime && hasEventType && !this.submitting;
  }

  changeUiStage = (stage) => {
    stage = this.getNextStep(stage);
    this.ui.stage = stage;
    this.recoRequestService.setData({ lead: this.lead, request: this.request, conversation: this.conversation, recommendation: this.recommendation, origin: this.origin, space: this.space });
    this.setScrollTop();
  }

  getNextStep = (stage) => {
    const stages = {
      [this.MENU_STAGE]: this.menus && this.menus.length,
      [this.DRINK_STAGE]: this.drinks && this.drinks.length,
      [this.ADDON_STAGE]: this.addOns && this.addOns.length
    };

    if (!Object.keys(stages).includes(stage)) {
      return stage;
    }

    if (stages[stage]) {
      return stage;
    } else {
      this.getNextStep(this.nextStage(stage));
    }
    return this.SUBMIT_STAGE;
  }

  nextStage = (stage) => {
    if (stage === this.MENU_STAGE) {
      return this.DATE_STAGE;
    } else if (stage === this.DRINK_STAGE) {
      return this.ADDON_STAGE;
    } else {
      return this.SUBMIT_STAGE;
    }
  }

  createEventData = () => {
    const data = {
      venue: get(this.venue, 'data.name'),
      venueCity: get(this.venue, 'data.address.city'),
    };
    if (this.request) {
      data['eventType'] = get(this.request, 'data.eventType');
      data['space'] = get(this.request, 'selectedSpace.data.name');
      data['duration'] = get(this.request, 'data.duration');
      data['date'] = get(this.request, 'data.date');
      data['groupSize'] = get(this.request, 'data.groupSize');
      data['time'] = integerToCivilianTime(get(this.request, 'data.time'), true);
      data['menu'] = get(this.request, 'data.menu.name');
      data['numberOfDrinks'] = get(this.request, 'data.drinks.length');
      data['numberOfAddOns'] = get(this.request, 'data.addOns.length');
    }
    return data;
  }

  trackEvent = (event) => {
    const data = this.createEventData();
    const eventAction = 'Select';
    this.$analytics.$trackEvent(event, data, eventAction);
  }
}

export const RecoRequestComponent = {
  template: require('./reco-request.component.jade'),
  controller: RecoRequestController,
  bindings: {
    request: '<',
    space: '<',
    menus: '<',
    drinks: '<',
    addOns: '<',
    venue: '<',
    conversation: '<',
    recommendation: '<',
    lead: '<',
    origin: '<',
    close: '&'
  }
};
